.profileContainer {
  /* border: 2px solid green; */
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  /* justify-items: center;
  align-items: center; */
}
.nameTag {
  /* border: 2px blue solid; */
  background-color: #ffe747;
  border-radius: 20px;
  z-index: 2;
  width: 40vh;
  height: 8vh;
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 3;
  grid-row-end: 4;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}

.nameText {
  font-size: 30px;
  font-weight: 700;
  text-shadow: 1px 1px;
  color: #000000;
}

.profilePic {
  /* border: red solid 2px; */
  border-radius: 20px;
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 10;
  width: 50vh;
  height: auto;
}

.profileDescription {
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 8;
  width: 40vh;
  font-weight: 400;
  font-size: 2.5vh;
}
