body {
  background-color: #fff;
  font-family: "NOto Sans JP", "sans-serif";
  display: grid;
  justify-content: center;
  align-items: center;
}
h4 {
  font-family: "Noto Sans JP", sans-serif;
}

/* Styling of the images in the landing page */
.isra {
  height: 10em;
  position: absolute;
  z-index: 2;
}
.main_image_with_label {
  display: grid;
  justify-content: center;
}
.lp_background {
  height: 38em;
  width: auto;
  /* justify-self: center; */
}
.label_background {
  justify-self: end;
}
